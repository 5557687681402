import React, { useEffect, useRef, useState } from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';

import Section from './section.jsx';

import useTranslate from '../hooks/use-translate.js';
import usePageContext from '../hooks/use-page-context.js';
import Title from './title.jsx';
import SubTitle from './sub-title.jsx';
import classNames from 'classnames';
import TestimonialsCarousel from './testimonials-carousel.jsx';

export default function Testimonials(props) {
	let { showQuotes = true, showTestimonialsCarousel = true } = props;
	let { language } = usePageContext();
	let data = useStaticQuery(query);
	let { allSanityTestimonial } = data;
	let { nodes: testimonials } = allSanityTestimonial;
	let t = useTranslate('testimonials');

	if (testimonials.length === 0) {
		return null;
	}

	let quotes = [];
	let renderTestimonials = [];
	for (let testimonial of testimonials) {
		if (testimonial.quote) {
			quotes.push({ ...testimonial.quote, company: testimonial.name });
		}
		if (testimonial.case?.slug?.current) {
			renderTestimonials.push(
				<Link
					key={testimonial.id}
					aria-label={testimonial.name}
					to={`/${language}/references/${testimonial.case?.slug?.current}`}
					className="mx-16 my-6"
				>
					<img src={testimonial.image.asset.url} alt={testimonial.name} className="h-20" />
				</Link>,
			);
		} else {
			renderTestimonials.push(
				<a
					key={testimonial.id}
					target="_blank"
					rel="noreferrer"
					aria-label={testimonial.name}
					href={testimonial.url}
					className="mx-16 my-6"
				>
					<img src={testimonial.image.asset.url} alt={testimonial.name} className="h-20" />
				</a>,
			);
		}
	}

	let renderQuotes;
	if (showQuotes) {
		renderQuotes = <Quotes quotes={quotes} />;
	}

	let renderTestimonialsCarousel;
	if (showTestimonialsCarousel) {
		renderTestimonialsCarousel = <TestimonialsCarousel testimonials={testimonials} />;
	}

	return (
		<Section>
			<Title>{t('title')}</Title>
			<SubTitle>{t('subtitle')}</SubTitle>

			{renderQuotes}
			{renderTestimonialsCarousel}
		</Section>
	);
}

function Quotes(props) {
	let { quotes } = props;
	let [autoRotate, setAutoRotate] = useState(true);

	let index = useRef(0);
	let [activeQuote, setActiveQuote] = useState(function () {
		return quotes[index.current];
	});

	useEffect(() => {
		if (!autoRotate) return;

		let interval = setInterval(function () {
			let newIndex = index.current + 1;
			index.current = newIndex;

			setActiveQuote(quotes[newIndex % quotes.length]);
		}, 8000);

		return () => {
			clearInterval(interval);
		};
	}, [autoRotate, quotes, index]);

	function handleDotClick(quote) {
		setActiveQuote(quote);
		setAutoRotate(false);
	}

	if (quotes.length === 0) {
		return null;
	}

	let renderDots = [];
	if (quotes.length > 1) {
		for (let quote of quotes) {
			let className = classNames(
				'w-2.5 h-2.5 border rounded-full border-primary transition duration-300 focus:outline-none',
				{
					'bg-primary': quote.text === activeQuote.text,
					'bg-transparent': quote.text !== activeQuote.text,
				},
			);

			renderDots.push(
				<button
					key={quote.text}
					aria-label={quote.text}
					onClick={() => handleDotClick(quote)}
					className={className}
				></button>,
			);
		}
	}

	return (
		<section className="max-w-5xl px-8 py-12 mx-auto">
			<div className="flex flex-col">
				<p className="text-2xl text-center sm:text-left">
					<span>{activeQuote.text}</span>
				</p>
				<div className="mt-4 border-t-2 sm:w-96 border-primary"></div>
				<div className="flex items-center justify-between pt-2">
					<p>
						{activeQuote.author} - {activeQuote.role}, {activeQuote.company}
					</p>
					<div className="flex items-center justify-center space-x-2">{renderDots}</div>
				</div>
			</div>
		</section>
	);
}

const query = graphql`
	query TestimonialsQuery {
		allSanityTestimonial {
			nodes {
				id
				name
				url
				quote {
					text
					author
					role
				}
				image {
					asset {
						url
					}
				}
				case {
					slug {
						current
					}
				}
			}
		}
	}
`;
