import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import usePageContext from '../hooks/use-page-context';
import { useScreenSizeIndex } from '../hooks/use-screen-size';

const SHOW_MAP = {
	[-1]: 1,
	0: 1,
	1: 2,
	2: 3,
	3: 3,
	4: 3,
};

export default function TestimonialsCarousel(props) {
	let { testimonials = [] } = props;
	let { language } = usePageContext();
	let screenSizeIndex = useScreenSizeIndex();
	let [page, setPage] = useState(0);

	let show = SHOW_MAP[screenSizeIndex];

	function handleNextClick() {
		let newPage = page + 1;
		if (newPage > testimonials.length - show) return;

		setPage(newPage);
	}

	function handlePreviousClick() {
		let newPage = page - 1;
		if (newPage < 0) return;

		setPage(newPage);
	}

	if (testimonials.length === 0) {
		return null;
	}

	let renderActiveTestimonials = [];
	for (let i = page; i < page + show; i++) {
		let testimonial = testimonials[i];
		if (testimonial?.case?.slug?.current) {
			renderActiveTestimonials.push(
				<Link key={testimonial.id} to={`/${language}/references/${testimonial.case?.slug?.current}`}>
					<img src={testimonial.image.asset.url+"?h=300"} alt={testimonial.name} />
				</Link>,
			);
		} else {
			renderActiveTestimonials.push(
				<a key={testimonial.id} target="_blank" rel="noreferrer" aria-label={testimonial.name} href={testimonial.url}>
					<img src={testimonial.image.asset.url+"?h=300"} alt={testimonial.name} />
				</a>,
			);
		}
		renderActiveTestimonials.push();
	}

	let previousButtonDisabled = page <= 0;
	let previousButtonClassName = classNames('text-white transition-transform transform focus:outline-none', {
		'focus-visible:text-primary hover:-translate-x-2 hover:text-primary': !previousButtonDisabled,
		'text-opacity-30 cursor-default': previousButtonDisabled,
	});

	let nextButtonDisabled = page >= testimonials.length - show;
	let nextButtonClassName = classNames('text-white transition-transform transform focus:outline-none', {
		'focus-visible:text-primary hover:translate-x-2 hover:text-primary': !nextButtonDisabled,
		'text-opacity-30 cursor-default': nextButtonDisabled,
	});

	return (
		<section className="grid items-x-center items-y-center max-w-7xl mx-auto gap-6 grid-cols-[max-content,1fr,max-content]">
			<button onClick={handlePreviousClick} className={previousButtonClassName}>
				<ChevronLeftIcon className="w-12 h-12" />
			</button>
			<div className="grid max-w-5xl grid-cols-1 gap-12 px-8 md:grid-cols-2 lg:grid-cols-3">
				{renderActiveTestimonials}
			</div>
			<button disabled={nextButtonDisabled} onClick={handleNextClick} className={nextButtonClassName}>
				<ChevronRightIcon className="w-12 h-12" />
			</button>
		</section>
	);
}
