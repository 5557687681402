import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from '../components/button.jsx';
import Layout from '../components/layout.jsx';
import Fade from '../components/fade.jsx';
import Section from '../components/section.jsx';
import TitleBlock from '../components/title-block.jsx';
import Testimonials from '../components/testimonials.jsx';
import FirstMeeting from '../components/first-meeting';

import usePageContext from '../hooks/use-page-context.js';
import Hero from '../components/hero.jsx';
import Title from '../components/title.jsx';
import RichContent from '../components/rich-content.jsx';

export default function Company(props) {
	let { data } = props;
	let { language } = usePageContext();
	let { sanityCompanyPage, allSanityVertical } = data;
	let { title, info, industries, seo } = sanityCompanyPage;
	let { nodes: verticals } = allSanityVertical;

	let renderVerticals = [];
	for (let vertical of verticals) {
		renderVerticals.push(
			<span className="flex-shrink-0 mx-2 my-2" key={vertical.id}>
				<Button as="link" to={`/${language}/industries?type=${vertical.name.toLowerCase()}`}>
					{vertical.name}
				</Button>
			</span>,
		);
	}

	// let renderTeamExamples = [];
	// for (let example of team?.examples || []) {
	// 	renderTeamExamples.push(<li key={example}>{example}</li>);
	// }

	return (
		<Layout seo={seo}>
			<Hero>
				<div className="grid gap-12 lg:grid-cols-2">
					<h1 className="text-4xl md:text-5xl 2xl:text-6xl xl:max-w-3xl self-y-center">
						<TitleBlock title={title} />
					</h1>

					<div className="relative w-full rounded-full lg:h-full lg:w-auto lg:max-w-none">
						<StaticImage src="../images/ship.svg" alt="Spaceship" className="" />
					</div>
				</div>
			</Hero>

			<Section>
				<div className="grid gap-12 lg:grid-cols-2 lg:gap-24 items-y-center">
					<div className="max-w-lg w-[90%] self-x-end">
						<StaticImage src="../images/company.svg" alt="Building" />
					</div>

					<div className="max-w-lg">
						<Title>{info.title}</Title>
						<div className="mt-4">
							<RichContent className="prose" blocks={info._rawDescription} />
						</div>
						<span className="inline-block mt-6">
							<Button as="link" to="./../solutions">
								Our solutions
							</Button>
						</span>
					</div>
				</div>
			</Section>

			<Section>
				<Title>Our approach</Title>
				<div className="ml-4 sm:ml-auto grid max-w-screen-lg gap-8 grid-cols-[max-content,max-content] grid-rows-2 pt-16 mx-auto text-center items-x-start items-y-center lg:gap-x-24 lg:gap-y-8 lg:grid-flow-col lg:grid-cols-5 lg:items-x-center">
					<div className="relative inline-flex items-center justify-center lg:w-32 lg:h-32">
						<Fade scale={Fade.scale.large} opacity={0.15} />
						<svg width="122" height="122" className="w-20 h-20 lg:w-28 lg:h-28" viewBox="0 0 122 122" fill="none">
							<path
								d="M57.7213 83.8751L55.2813 53.8898C55.2353 53.172 54.971 52.4855 54.5237 51.9222C54.0765 51.3589 53.4677 50.9459 52.779 50.7385C52.0903 50.531 51.3547 50.5391 50.6708 50.7617C49.9868 50.9842 49.3873 51.4106 48.9525 51.9835V51.9835C48.5371 52.5069 48.2788 53.1375 48.2079 53.8019C48.137 54.4663 48.2563 55.1372 48.5519 55.7365C48.8475 56.3357 49.3073 56.8386 49.8777 57.1867C50.4481 57.5347 51.1057 57.7135 51.7738 57.7023H70.3407C71.0088 57.7135 71.6663 57.5347 72.2367 57.1867C72.8071 56.8386 73.2669 56.3357 73.5625 55.7365C73.8582 55.1372 73.9775 54.4663 73.9065 53.8019C73.8356 53.1375 73.5774 52.5069 73.1619 51.9835V51.9835C72.7272 51.4106 72.1276 50.9842 71.4437 50.7617C70.7597 50.5391 70.0241 50.531 69.3354 50.7385C68.6468 50.9459 68.038 51.3589 67.5907 51.9222C67.1435 52.4855 66.8791 53.172 66.8332 53.8898L64.2788 83.8751"
								stroke="#DBFF3B"
								strokeWidth="2"
								strokeMiterlimit="5"
							/>
							<path
								d="M71.3127 83.875C71.3127 73.5622 79.6049 64.2978 83.894 57.8356C87.1947 52.881 88.7877 46.9843 88.4308 41.0416C87.6874 27.3547 78.1562 15.25 60.9999 15.25C43.8436 15.25 33.5308 26.84 33.5308 42.6619C33.5185 48.1611 35.1661 53.536 38.2583 58.0834C42.6427 64.5266 50.6871 73.5622 50.6871 83.875"
								stroke="#DBFF3B"
								strokeWidth="2"
								strokeMiterlimit="5"
							/>
							<path
								d="M53.375 99.125C53.375 101.147 54.1783 103.087 55.6083 104.517C57.0383 105.947 58.9777 106.75 61 106.75C63.0223 106.75 64.9617 105.947 66.3917 104.517C67.8217 103.087 68.625 101.147 68.625 99.125"
								stroke="#DBFF3B"
								strokeWidth="2"
								strokeMiterlimit="5"
							/>
							<path
								d="M70.5312 83.875H51.4688C48.3104 83.875 45.75 86.4354 45.75 89.5938V93.4062C45.75 96.5646 48.3104 99.125 51.4688 99.125H70.5312C73.6896 99.125 76.25 96.5646 76.25 93.4062V89.5938C76.25 86.4354 73.6896 83.875 70.5312 83.875Z"
								stroke="#DBFF3B"
								strokeWidth="2"
								strokeMiterlimit="5"
							/>
						</svg>
					</div>
					<span className="lg:max-w-[6rem]">Identify possibilities</span>
					<div className="relative inline-flex items-center justify-center lg:w-32 lg:h-32">
						<Fade scale={Fade.scale.large} opacity={0.15} />
						<svg width="122" height="122" className="w-20 h-20 lg:w-28 lg:h-28" viewBox="0 0 122 122" fill="none">
							<path
								d="M53.375 91.5C74.4309 91.5 91.5 74.4309 91.5 53.375C91.5 32.3191 74.4309 15.25 53.375 15.25C32.3191 15.25 15.25 32.3191 15.25 53.375C15.25 74.4309 32.3191 91.5 53.375 91.5Z"
								stroke="#DBFF3B"
								strokeWidth="2"
								strokeMiterlimit="5"
							/>
							<path d="M106.75 106.75L80.3296 80.3293" stroke="#DBFF3B" strokeWidth="2" strokeMiterlimit="5" />
						</svg>
					</div>
					<span className="lg:max-w-[8rem]">Data analysis and exploration</span>

					<div className="relative inline-flex items-center justify-center lg:w-32 lg:h-32">
						<Fade scale={Fade.scale.large} opacity={0.15} />
						<svg width="122" height="122" className="w-20 h-20 lg:w-28 lg:h-28" viewBox="0 0 122 122" fill="none">
							<path
								d="M105.835 35.0178L99.4492 41.4228C96.9467 43.9254 93.5525 45.3313 90.0133 45.3313C86.4741 45.3313 83.0799 43.9254 80.5774 41.4228C78.0748 38.9202 76.6689 35.526 76.6689 31.9869C76.6689 28.4477 78.0748 25.0535 80.5774 22.5509L86.9824 16.1459C84.7229 15.5564 82.3978 15.2554 80.0627 15.25C75.7871 15.2492 71.5739 16.2758 67.7779 18.2431C63.9818 20.2105 60.714 23.0612 58.2496 26.5551C55.7852 30.0489 54.1963 34.0838 53.6167 38.3199C53.0372 42.5561 53.4839 46.8694 54.9192 50.8969L19.7108 80.7106C16.8493 83.5721 15.2417 87.4532 15.2417 91.5C15.2417 95.5468 16.8493 99.4279 19.7108 102.289C22.5723 105.151 26.4534 106.758 30.5002 106.758C34.547 106.758 38.428 105.151 41.2896 102.289L71.1033 67.0809C75.6892 68.7151 80.6348 69.0637 85.4047 68.089C90.1745 67.1143 94.5869 64.8534 98.164 61.5511C101.741 58.2488 104.347 54.0309 105.699 49.354C107.051 44.6772 107.098 39.7195 105.835 35.0178V35.0178Z"
								stroke="#DBFF3B"
								strokeWidth="2"
								strokeMiterlimit="5"
							/>
						</svg>
					</div>
					<span className="lg:max-w-[6rem]">Model building</span>

					<div className="relative inline-flex items-center justify-center lg:w-32 lg:h-32">
						<Fade scale={Fade.scale.large} opacity={0.15} />
						<svg width="122" height="122" className="w-20 h-20 lg:w-28 lg:h-28" viewBox="0 0 122 122" fill="none">
							<path
								d="M45.75 99.125L106.75 38.125L83.875 15.25L22.875 76.25L15.25 106.75L45.75 99.125Z"
								stroke="#DBFF3B"
								strokeWidth="2"
								strokeMiterlimit="5"
							/>
							<path d="M22.875 76.25L45.75 99.125" stroke="#DBFF3B" strokeWidth="2" strokeMiterlimit="5" />
							<path d="M68.625 30.5L91.5 53.375" stroke="#DBFF3B" strokeWidth="2" strokeMiterlimit="5" />
						</svg>
					</div>
					<span className="lg:max-w-[6rem]">UX and interface</span>

					<div className="relative inline-flex items-center justify-center lg:w-32 lg:h-32">
						<Fade scale={Fade.scale.large} opacity={0.15} />
						<svg width="122" height="122" className="w-20 h-20 lg:w-28 lg:h-28" viewBox="0 0 122 122" fill="none">
							<path
								d="M106.75 15.25H15.25V83.875H106.75V15.25Z"
								stroke="#DBFF3B"
								strokeWidth="2"
								strokeMiterlimit="5"
							/>
							<path d="M22.875 106.75H99.125" stroke="#DBFF3B" strokeWidth="2" strokeMiterlimit="5" />
							<path d="M76.25 83.875V106.75" stroke="#DBFF3B" strokeWidth="2" strokeMiterlimit="5" />
							<path d="M45.75 83.875V106.75" stroke="#DBFF3B" strokeWidth="2" strokeMiterlimit="5" />
							<path
								d="M87.6875 30.5L49.5625 68.625L34.3125 53.375"
								stroke="#DBFF3B"
								strokeWidth="2"
								strokeMiterlimit="5"
							/>
						</svg>
					</div>
					<span className="lg:max-w-[8rem]">Integration and deployment</span>
				</div>
			</Section>

			<Section>
				<div className="grid gap-12 lg:grid-cols-2 lg:gap-24 items-y-center">
					<div className="max-w-lg w-[90%] lg:self-x-end">
						<StaticImage src="../images/hero-faq.svg" alt="Building" />
					</div>

					<div className="max-w-2xl">
						<Title>{industries.title}</Title>
						<div className="mt-8">
							<RichContent className="prose prose-lg" blocks={industries._rawDescription} />
						</div>
						<div className="flex flex-wrap items-center justify-center mt-8 -mx-2 -my-2 lg:justify-start">
							{renderVerticals}
						</div>
					</div>
				</div>
			</Section>

			{/* <Section>
				<div className="grid gap-12 md:mt-4 xl:grid-cols-2 items-y-center">
					<div>
						<h3 className="text-3xl text-white">{team.title}</h3>
						<h2 className="mt-4 text-2xl md:mt-8 xl:mt-16">
							<TitleBlock title={team.description} />
						</h2>
						<ul className="mt-4 ml-4 space-y-2 list-disc list-outside lg:ml-12">{renderTeamExamples}</ul>
						<div className="flex justify-center mt-8 md:block">
							<Button as="link" to={`/${language}/team`}>
								Meet the team
							</Button>
						</div>
					</div>

					<ImageCarousel images={team.images || []} />
				</div>
			</Section> */}

			<Testimonials />

			<FirstMeeting />
		</Layout>
	);
}

export const query = graphql`
	query CompanyPage($language: String!) {
		sanityCompanyPage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			info {
				title
				_rawDescription(resolveReferences: { maxDepth: 5 })
			}
			industries {
				title
				_rawDescription(resolveReferences: { maxDepth: 5 })
			}
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}
		allSanityVertical(filter: { lang: { eq: $language } }) {
			nodes {
				id
				name
			}
		}
	}
`;

// team {
// 	title
// 	examples
// 	description {
// 		children {
// 			_key
// 			text
// 			marks
// 		}
// 	}
// 	images {
// 		asset {
// 			url
// 		}
// 	}
// }
